import React from "react";
const FooterFirstColumn=React.lazy(()=>import( "./footer_first_column"));
const FooterSecondColumn=React.lazy(()=>import( "./footer_second_column"));
const FooterThirdColumn=React.lazy(()=>import( "./footer_third_column"));
const FooterMobileView=React.lazy(()=>import( "./footer_mobile_view"));

const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer_main_container">
        <FooterFirstColumn />
        <FooterSecondColumn />
        <FooterThirdColumn />
      </div>
      <FooterMobileView />
    </React.Fragment>
  );
};

export default Footer;
