import UserLayout from "@src/views/layouts/user_layout";
import React from "react";

// const UserLayout = React.lazy(() => import("@src/views/layouts/user_layout"));
const AboutUs = React.lazy(() => import("@src/views/pages/about_us"));
const FactCheck = React.lazy(() => import("@src/views/pages/fact_check"));
const Home = React.lazy(() => import("@src/views/pages/home"));
const SearchResults = React.lazy(() =>
  import("@src/views/pages/search_results")
);
const SingleFactCheck = React.lazy(() =>
  import("@src/views/pages/single_fact_check")
);
const UserContribution = React.lazy(() =>
  import("@src/views/pages/user_contribution")
);
const Videos = React.lazy(() => import("@src/views/pages/videos"));
const Articles = React.lazy(() => import("@src/views/pages/articles"));
const SingleArticle = React.lazy(() =>
  import("@src/views/pages/single_article")
);
const UserProfile = React.lazy(() => import("@src/views/pages/user_profile"));
const WriterProfile = React.lazy(() =>
  import("@src/views/pages/writer_profile")
);
const ContactUs = React.lazy(() => import("@src/views/pages/contact_us"));
const SupportUs = React.lazy(() => import("@src/views/pages/support_us"));
const Login = React.lazy(() => import("@src/views/pages/login_page"));
const UserDonation = React.lazy(() =>
  import("@src/views/components/user_profile/donation/user_donation")
);
const UserClaims = React.lazy(() =>
  import("@src/views/components/user_profile/claims/user_claims")
);
const FactCheckContribution = React.lazy(() =>
  import(
    "@src/views/components/user_contributions_form/fact_check_contribution"
  )
);
const UserBookmarkArticles = React.lazy(() =>
  import("@src/views/pages/user_bookmark_articles")
);
const UserBookmarkFactChecks = React.lazy(() =>
  import("@src/views/pages/user_bookmark_fact_check")
);
const UserContributionFactCheck = React.lazy(() =>
  import("@src/views/pages/user_contribution_fact_check")
);
const UserContributionArticles = React.lazy(() =>
  import("@src/views/pages/user_contribution_article")
);
const ArticleCheckContribution = React.lazy(() =>
  import("@src/views/components/user_contributions_form/article_contribution")
);
const ForgotPassword = React.lazy(() =>
  import("@src/views/pages/forgot_password")
);
const TopContributors = React.lazy(() =>
  import("@src/views/pages/top_contributors")
);
const WriterContributionList = React.lazy(() =>
  import("@src/views/components/writer_profile/writer_contribution_list")
);
const PageNotFound = React.lazy(() =>
  import("@src/views/pages/page_not_found")
);
const NotFound = React.lazy(() =>
  import("@src/views/components/auth/not_found")
);
const ResetPassword = React.lazy(() =>
  import("@src/views/pages/reset_password")
);
const PoliticalInvolvementPolicy = React.lazy(() =>
  import("@src/views/pages/political_involvement_policy")
);
const CorrectionPolicy = React.lazy(() =>
  import("@src/views/pages/correction_policy")
);
const PrivacyPolicy = React.lazy(() =>
  import("@src/views/pages/privacy_policy")
);
const RssFeed = React.lazy(() => import("@src/views/pages/rss_feed"));
const RedirectHandler = React.lazy(() =>
  import("@src/views/pages/redirect_handler")
);

export const ROUTES = {
  HOME: "/home",
  VIDEOS: "/videos",
  ABOUT_US: "/about-us",
  SINGLE_FACT_CHECK: "/factcheck",
  SEARCH_RESULT_LIST: "/search-results",
  FACT_CHECK: "/fact-check",
  USER_CONTRIBUTION: "/user-contribution",
  ARTICLES: "/article",
  SINGLE_ARTICLE: "/articles",
  WRITER_PROFILE: "/writer-profile",
  USER_PROFILE: "/user-profile",
  CONTACT_US: "/contact-us",
  SUPPORT_US: "support-us",
  USER_DONATION: "/donations",
  LOGIN: "/login",
  USER_CLAIMS: "/claims",
  USER_ARTICLE_FORM: "/user-article-form",
  USER_FACT_CHECK_FORM: "/user-fact-check-form",
  BOOKMARK_ARTICLES: "/bookmark/articles",
  BOOKMARK_FACT_CHECK: "/bookmark/fact-check",
  USER_FACT_CHECKS: "/profiles/my-fact-check",
  USER_ARTICLES: "/profiles/my-articles",
  TOP_CONTRIBUTORS: "/top-contributors",
  FORGOT_PASSWORD: "/forgot-password",
  WRITER_CONTRIBUTION_LIST: "/writer-contribution-list",
  PAGE_NOT_FOUND: "/page-not-found",
  RESET_PASSWORD: "/reset-password",
  POLITICAL_INVOLVEMENT_POLICY: "/political-involvement-policy",
  CORRECTION_POLICY: "/correction-policy",
  PRIVACY_POLICY: "/privacy-policy",
  RSS_FEED: "/RSS",
  REDIRECT_HANDLER: "/redirect",
};

const my_routes = [
  {
    path: "/",
    name: "Layout",
    authenticate: false,
    component: <UserLayout />,
    children: [
      {
        path: "",
        name: "Home",
        authenticate: false,
        component: <Home />,
      },
      {
        path: `${ROUTES.HOME}/:id?`,
        name: "Home",
        authenticate: false,
        component: <Home />,
      },
      {
        path: ROUTES.VIDEOS,
        name: "Videos",
        authenticate: false,
        component: <Videos />,
      },
      {
        path: ROUTES.ABOUT_US,
        name: "About Us",
        authenticate: false,
        component: <AboutUs />,
      },

      {
        path: `${ROUTES.SINGLE_FACT_CHECK}/:perma_link?`,
        name: "Fact Check",
        authenticate: false,
        component: <SingleFactCheck />,
      },
      {
        path: ROUTES.SEARCH_RESULT_LIST,
        name: "Search Results",
        authenticate: false,
        component: <SearchResults />,
      },
      {
        path: ROUTES.FACT_CHECK,
        name: "Fact Check",
        authenticate: false,
        component: <FactCheck />,
      },
      {
        path: ROUTES.USER_CONTRIBUTION,
        name: "User Contribution",
        authenticate: false,
        component: <UserContribution />,
      },
      {
        path: ROUTES.ARTICLES,
        name: "Articles",
        authenticate: false,
        component: <Articles />,
      },
      {
        path: `${ROUTES.SINGLE_ARTICLE}/:perma_link`,
        name: "Article",
        authenticate: false,
        component: <SingleArticle />,
      },
      {
        path: `${ROUTES.WRITER_PROFILE}/:author_name?`,
        name: "WriterProfile",
        authenticate: false,
        component: <WriterProfile />,
      },
      {
        path: ROUTES.USER_PROFILE,
        name: "UserProfile",
        authenticate: false,
        component: <UserProfile />,
      },
      {
        path: ROUTES.CONTACT_US,
        name: "ContactUs",
        authenticate: false,
        component: <ContactUs />,
      },
      {
        path: ROUTES.SUPPORT_US,
        name: "SupportUs",
        authenticate: false,
        component: <SupportUs />,
      },
      {
        path: ROUTES.USER_DONATION,
        name: "Donation History",
        authenticate: false,
        component: <UserDonation />,
      },
      {
        path: ROUTES.CONTACT_US,
        name: "ContactUs",
        authenticate: false,
        component: <ContactUs />,
      },
      {
        path: ROUTES.SUPPORT_US,
        name: "SupportUs",
        authenticate: false,
        component: <SupportUs />,
      },
      {
        path: ROUTES.USER_DONATION,
        name: "Donation History",
        authenticate: false,
        component: <UserDonation />,
      },
      {
        path: ROUTES.USER_CLAIMS,
        name: "Claims",
        authenticate: false,
        component: <UserClaims />,
      },
      {
        path: `${ROUTES.USER_FACT_CHECK_FORM}/:id?`,
        name: "Fact Check",
        authenticate: false,
        component: <FactCheckContribution />,
      },
      {
        path: ROUTES.BOOKMARK_ARTICLES,
        name: "Bookmarks",
        authenticate: false,
        component: <UserBookmarkArticles />,
      },
      {
        path: ROUTES.BOOKMARK_FACT_CHECK,
        name: "Bookmarks",
        authenticate: false,
        component: <UserBookmarkFactChecks />,
      },
      {
        path: ROUTES.USER_FACT_CHECKS,
        name: "Fact Checks",
        authenticate: false,
        component: <UserContributionFactCheck />,
      },
      {
        path: ROUTES.USER_ARTICLES,
        name: "Fact Checks",
        authenticate: false,
        component: <UserContributionArticles />,
      },
      {
        path: `${ROUTES.USER_ARTICLE_FORM}/:id?`,
        name: "Article",
        authenticate: false,
        component: <ArticleCheckContribution />,
      },
      {
        path: `${ROUTES.TOP_CONTRIBUTORS}/:id?`,
        name: "Top Contributors",
        authenticate: false,
        component: <TopContributors />,
      },
      {
        path: `${ROUTES.WRITER_CONTRIBUTION_LIST}/:name`,
        name: "Writer Contribution List",
        authenticate: false,
        component: <WriterContributionList />,
      },
      {
        path: ROUTES.PAGE_NOT_FOUND,
        name: "Page Not Found",
        authenticate: false,
        component: <NotFound />,
      },
      {
        path: ROUTES.POLITICAL_INVOLVEMENT_POLICY,
        name: "Political Involvement Policy",
        authenticate: false,
        component: <PoliticalInvolvementPolicy />,
      },
      {
        path: ROUTES.CORRECTION_POLICY,
        name: "Correction Policy",
        authenticate: false,
        component: <CorrectionPolicy />,
      },
      {
        path: ROUTES.PRIVACY_POLICY,
        name: "Privacy Policy",
        authenticate: false,
        component: <PrivacyPolicy />,
      },
    ],
  },
  {
    path: `${ROUTES.RSS_FEED}.xml`,
    name: "Rss Feed",
    authenticate: false,
    component: <RssFeed />,
  },
  {
    path: ROUTES.REDIRECT_HANDLER,
    name: "Redirect Handler",
    authenticate: false,
    component: <RedirectHandler />,
  },
  {
    path: ROUTES.LOGIN,
    name: "Login",
    component: <Login />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    name: "Forgot Password",
    component: <ForgotPassword />,
  },
  {
    path: `${ROUTES.RESET_PASSWORD}/:token`,
    name: "Reset Password",
    component: <ResetPassword />,
  },
];

export default my_routes;
