import { YOU_TURN_LOGO_BLUE } from "@src/helpers/image_constants";
import { dynamic_set } from "@src/services/redux";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const Loading = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dynamic_set("set_is_drawer_open", { is_drawer_open: false }));
  }, []);
  return (
    <div id="preloader">
      <div id="loader" class="loader">
        <div class="loader-container">
          <div class="loader-icon">
            <img src={YOU_TURN_LOGO_BLUE} alt="Preloader" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
