import React from "react";
import HeaderDrawerUserDetails from "./header_drawer_user_details";
import HeaderDrawerMenuOptions from "./header_drawer_menu_options";
import HeaderDrawerSocialMedia from "./header_drawer_social_media";
import HeaderDrawerDarkTheme from "./hearder_drawer_dark_theme";
import QuickAccessLanguages from "../quick_access_bar/quick_access_languages";
import QuickAccessTheme from "../quick_access_bar/quick_access_theme";

const HeaderDrawerOptions = (props) => {
  const { handle_drawer_close } = props;
  return (
    <>
      <HeaderDrawerDarkTheme handle_drawer_close={handle_drawer_close} />
      <div className="header_drawer_theme">
        <QuickAccessLanguages />
        <QuickAccessTheme />
      </div>
      <HeaderDrawerUserDetails handle_drawer_close={handle_drawer_close} />
      <HeaderDrawerMenuOptions handle_drawer_close={handle_drawer_close} />
      <HeaderDrawerSocialMedia />
    </>
  );
};

export default HeaderDrawerOptions;
