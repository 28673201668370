import { Form, InputNumber } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const MobileNo = () => {
  const { t } = useTranslation();
  return (
    <Form.Item
      name="mobile"
      rules={[
        { required: true, message: t("please_enter_your_mobile") },
        {
          pattern: /^\d{10}$/,
          message: "Please enter a 10-digit number",
        },
      ]}
    >
      <InputNumber
        style={{ width: "100%" }}
        placeholder={t("mobile")}
        maxLength={10}
        controls={false}
        changeOnWheel={false}
      />
    </Form.Item>
  );
};

export default MobileNo;
