import { gql } from "@apollo/client";

export const create_user_claim_mutation=gql`
mutation create_user_claim($json: create_user_claim_input) {
    create_user_claim(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }`