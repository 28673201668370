import { HEADER_LOGO } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { Image } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const HeaderLogo = () => {
  const navigate = useNavigate()
  const handle_home = () => {
    navigate(ROUTES.HOME)
  }
  return (
    <div className="user_layout_logo_container" onClick={handle_home}>
      <Image preview={false} className="user_layout_logo" src={HEADER_LOGO} />
    </div>
  );
};

export default HeaderLogo;
