import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { dynamic_set } from "@src/services/redux";
export let is_dark_mode = false;
const DarkModeButton = () => {
  const [dark_mode, set_dark_mode] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const dark_mode_stored = localStorage.getItem("dark_mode");
    if (dark_mode_stored === "true") {
      set_dark_mode(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("dark_mode", dark_mode);
    is_dark_mode = localStorage.setItem("dark_mode", dark_mode) || dark_mode;
    const icon = document.querySelector(".btn_icon");
    icon.classList.add("animated");

    if (dark_mode) {
      dispatch(dynamic_set("dark_mode", { dark_mode: dark_mode }));
      document.body.classList.add("dark_mode");
    } else {
      document.body.classList.remove("dark_mode");
    }
    setTimeout(() => {
      icon.classList.remove("animated");
    }, 500);
  }, [dark_mode]);

  const handle_click = () => {
    set_dark_mode(!dark_mode);
    dispatch(dynamic_set("dark_mode", { dark_mode: !dark_mode }));
  };

  return (
    <div
      className={dark_mode ? "dark_mode_dm_btn" : "dm_btn"}
      onClick={handle_click}
    >
      <div className={dark_mode ? "dark_mode_btn_indicator" : "btn_indicator"}>
        <div className="btn_icon_container">
          <FontAwesomeIcon
            className={`btn_icon ${
              dark_mode ? "dark_mode_btn_icon_color" : ""
            }`}
            icon={dark_mode ? faMoon : faSun}
          />
        </div>
      </div>
    </div>
  );
};

export default DarkModeButton;
