import { retrieve_item } from "@src/helpers/cryptos";
import {
  FB_LOGO,
  INSTAGRAM_LOGO,
  LINKEDIN_LOGO,
  TELEGRAM_LOGO,
  TWITTER_LOGO,
  YOUTUBE_OUTLINED,
} from "@src/helpers/image_constants";
import { Image } from "antd";
import React from "react";

const HeaderDrawerSocialMedia = () => {
  let language = retrieve_item("language");

  const redirect_to_social_media = (socialMedia) => {
    switch (socialMedia) {
      case "Instagram":
        window.open("https://www.instagram.com/youturn.media/", "_blank");
        break;
      case "Facebook":
        window.open("https://facebook.com/youturn.in/", "_blank");
        break;
      case "Twitter":
        window.open("https://twitter.com/youturn_in", "_blank");
        break;
      case "YouTube":
        const channelUrl =
          language?.name === "tamil"
            ? "https://www.youtube.com/channel/UCCLsLUlxvfdnRwG8_Uh40Ew"
            : "https://www.youtube.com/@youturnenglish5337";

        window.open(channelUrl, "_blank");
        break;
      case "LinkedIn":
        window.open("https://www.linkedin.com/login", "_blank");
        break;
      case "Telegram":
        window.open("https://t.me/youturn_in", "_blank");
        break;
      case "Whatsapp":
        window.open(
          "https://api.whatsapp.com/send?phone=916369748351&text=Hi%20You%20Turn!",
          "_blank"
        );
        break;
      default:
    }
  };

  return (
    <>
      <div className="header_drawer_social_media_container">
        <div className="header_drawer_social_media_wrapper">
          <Image
            preview={false}
            className="drawer_menu_icon"
            src={TWITTER_LOGO}
            onClick={() => redirect_to_social_media("Twitter")}
          />
          <Image
            preview={false}
            className="drawer_menu_icon"
            src={INSTAGRAM_LOGO}
            onClick={() => redirect_to_social_media("Instagram")}
          />
          <Image
            preview={false}
            className="drawer_menu_icon"
            src={LINKEDIN_LOGO}
            onClick={() => redirect_to_social_media("LinkedIn")}
          />

          <Image
            preview={false}
            className="drawer_menu_icon"
            src={YOUTUBE_OUTLINED}
            onClick={() => redirect_to_social_media("YouTube")}
          />
        </div>
      </div>

      <div className="header_drawer_social_media_container">
        <div className="header_drawer_social_small_wrapper">
          <Image
            preview={false}
            className="drawer_menu_icon"
            src={FB_LOGO}
            onClick={() => redirect_to_social_media("Facebook")}
          />
          <Image
            preview={false}
            className="drawer_menu_icon"
            src={TELEGRAM_LOGO}
            onClick={() => redirect_to_social_media("Telegram")}
          />
        </div>
      </div>
    </>
  );
};

export default HeaderDrawerSocialMedia;
