import React, { useState } from "react";
import HeaderDrawerLogo from "./header_drawer_logo";
import HeaderLogo from "./header_logo";
import HeaderSearch from "./header_search";
import HeaderMenus from "./header_menus";
// const HeaderMenus = React.lazy(() => import("./header_menus"));
// const HeaderSearch = React.lazy(() => import("./header_search"));
// const HeaderLogo = React.lazy(() => import("./header_logo"));

const HeaderBar = ({ handle_show_search }) => {
  return (
    <div className="user_layout_header ">
      <div className="user_layout_container">
        <HeaderLogo />
        <HeaderSearch handle_show_search={handle_show_search} />
        <HeaderMenus />
        <HeaderDrawerLogo />
      </div>
    </div>
  );
};

export default HeaderBar;
