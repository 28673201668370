import { clear_local_storage, retrieve_item } from "@src/helpers/cryptos";
import { useAPIRequest } from "@src/helpers/hooks";
import { USER_PROFILE } from "@src/helpers/image_constants";
import { ROUTES } from "@src/route/my_routes";
import { get_user_query } from "@src/services/graphql/graphql_user_profile";
import { dynamic_clear, useDynamicSelector } from "@src/services/redux";
import { Button, Image, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const HeaderDrawerUserDetails = ({ handle_drawer_close }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_id = retrieve_item("user_id");

  const user_details = useDynamicSelector("get_user");

  const handle_click = (values) => {
    if (values) {
      dispatch(dynamic_clear("get_user"));
      clear_local_storage();
    } else {
      navigate(ROUTES.LOGIN);
    }
  };

  let get_user = useAPIRequest("get_user", get_user_query);
  const get_user_details = (id) => {
    get_user({
      id,
    });
  };

  useEffect(() => {
    if (user_id) {
      get_user_details(user_id);
    }
  }, [user_id]);
  const handle_redirect_profile = () => {
    if (user_id) {
      navigate(ROUTES.USER_PROFILE);
      handle_drawer_close(false);
    } else {
      navigate(ROUTES.LOGIN);
      handle_drawer_close(false);
    }
  };
  return (
    <div className="drawer_user_detail_container">
      <div className="drawer_user_profile_container">
        <Image
          preview={false}
          className="drawer_user_profile"
          src={user_details?.user_profile?.profile_pic || USER_PROFILE}
          onClick={handle_redirect_profile}
        />
      </div>
      <div
        className="drawer_welcome_container"
        onClick={handle_redirect_profile}
      >
        <Typography className="drawer_user_name_text">
          {`Hello, ${user_details?.user_profile?.name || "Guest"}`}
        </Typography>
      </div>
      <div className="User_login_button_container">
        <Button
          className="User_login_button"
          onClick={() => {
            handle_click(user_id);
          }}
        >
          {user_id ? "Sign out" : "Sign In"}
        </Button>
      </div>
    </div>
  );
};

export default HeaderDrawerUserDetails;
