import { DOWNLOAD_APP, WRITING_IMAGE } from "@src/helpers/image_constants";
import { Modal } from "antd";
import React, { useState } from "react";
import SubmitClaimForm from "./submit_claim_form";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
const SubmitClaim = ({ writing_image_opacity }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [claim_form_open, set_claim_form_open] = useState(false);

  const on_click_writing_image = () => {
    set_claim_form_open(true);
  };

  const on_close_modal = () => {
    set_claim_form_open(false);
  };

  return (
    <>
      <div
        className="writing_image_div"
        style={{ opacity: writing_image_opacity }}
      >
        <img
          alt={"writing_icon"}
          src={WRITING_IMAGE}
          className="writing_image"
          onClick={on_click_writing_image}
        />
      </div>

      <Modal
        className="submit_claim_modal"
        open={claim_form_open}
        onCancel={on_close_modal}
        destroyOnClose={true}
        footer={null}
        closeIcon={<IoMdClose className="report_modal_close_icon" />}
      >
        <SubmitClaimForm on_close_modal={on_close_modal} />
      </Modal>
    </>
  );
};
export default SubmitClaim;
