import { DRAWER_CLOSE_ICON } from "@src/helpers/image_constants";
import { dynamic_set } from "@src/services/redux";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const HeaderDrawerDarkTheme = ({ handle_drawer_close }) => {
  const { t } = useTranslation();
  return (
    <div className="header_drawer_close_container">
      <img
        alt={"close icon"}
        src={DRAWER_CLOSE_ICON}
        className="drawer_close_icon"
        onClick={handle_drawer_close}
      />
    </div>
  );
};

export default HeaderDrawerDarkTheme;
