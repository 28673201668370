import React, { createContext, useEffect, useState } from "react";
import { Drawer } from "antd";
import { dynamic_set, useDynamicSelector } from "@src/services/redux";
import { useDispatch } from "react-redux";
import HeaderDrawerOptions from "./header_drawer_options";
import { useLocation } from "react-router-dom";

const HeaderBarDrawer = (props) => {
  const { handle_drawer_close } = props;
  const { is_drawer_open } = useDynamicSelector("set_is_drawer_open");
  const screen_width = window.innerWidth;
  const dispatch = useDispatch();
  const placement = screen_width <= 1023 ? "left" : "right";

  let drawer_width;
  if (screen_width <= 480) {
    drawer_width = "";
  } else if (screen_width <= 768) {
    drawer_width = "";
  } else if (screen_width <= 1023) {
    drawer_width = "";
  } else if (screen_width <= 2559) {
    drawer_width = 360;
  } else {
    drawer_width = 600;
  }

  const handle_close = () => {
    dispatch(dynamic_set("set_is_drawer_open", { is_drawer_open: false }));
  };

  return (
    <Drawer
      className={`header_drawer`}
      // mask={true}
      // destroyOnClose
      // maskClosable={true}
      maskClosable={true} // Allows closing when clicking outside the drawer
      destroyOnClose={true}
      placement={placement}
      open={is_drawer_open}
      footer={null}
      width={drawer_width}
      onClose={handle_close}
    >
      <HeaderDrawerOptions handle_drawer_close={handle_close} />
    </Drawer>
  );
};

export default HeaderBarDrawer;
