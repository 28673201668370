import React from "react";
// const QuickAccessBookmarks=React.lazy(()=>import( "./quick_access_bookmarks"));
// const QuickAccessLanguages=React.lazy(()=>import( "./quick_access_languages"));
// const QuickAccessTheme=React.lazy(()=>import( "./quick_access_theme"));
import { retrieve_item } from "@src/helpers/cryptos";
import QuickAccessBookmarks from "./quick_access_bookmarks";
import QuickAccessLanguages from "./quick_access_languages";
import QuickAccessTheme from "./quick_access_theme";

const QuickAccessBar = () => {
  const user_id = retrieve_item("user_id");

  return (
    <div className="quick_access_container">
      <div className="quick_access_wrapper">
        {user_id && <QuickAccessBookmarks />}
        <QuickAccessLanguages />
        <QuickAccessTheme />
      </div>
    </div>
  );
};

export default QuickAccessBar;
